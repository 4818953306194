import styled from 'styled-components';

const PersonalBlogWrapper = styled.div`


background-color: #fff;

`;


export default PersonalBlogWrapper;
