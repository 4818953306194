import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
// import About from '../containers/about';
import PersonalBlog from '../containers/home';
type AboutPageProps = {};

const Blog: React.FunctionComponent<AboutPageProps> = () => {
  return (
    <Layout>
      <SEO
        title="Dutch Tax Calculator blog"
        description="Read articles about Dutch tax system in English made for exparts. Get to know how your taxes are calculated and what are tax brackets"
      />

      {/* <About /> */}
      <PersonalBlog />
    </Layout>
  );
};

export default Blog;
